import React from 'react';
import './Events.css';
import '../../App.css';
import Titleimage from '../Titleimage';

function Events() {
  return (
  <div>
  <Titleimage heading='Eventos' />
  <div className='eventos'>
    <div className='eventos-container'>

   

    </div>





  </div>



  </div>

  );
}

export default Events;