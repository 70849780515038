import React from 'react';
import '../../App.css';
import './ContactUs.css';
import emailjs from 'emailjs-com'
import Titleimage from '../Titleimage';



export default function Contact() {

    function sendEmail(e) {
        e.preventDefault();

    emailjs.sendForm('service_854o7kh', 'template_gzy3ksm', e.target, 'user_wgG9gkRK3PNaZjWzZ5M8L')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
        e.target.reset()
    }

    return(
        <div>
               <Titleimage heading='Contacto' text='Nos encanta saber de ti y poderte ayudar en lo que necesites.' />
            <div className="contact">
            <form onSubmit={sendEmail}>
                    <div className="row pt-5 mx-auto">
                        <div className="col-8 form-group mx-auto">
                            <input type="text" className="form-control" placeholder="Nombre" name="name"/>
                        </div>
                        <div className="col-8 form-group pt-2 mx-auto">
                            <input type="email" className="form-control" placeholder="Correo Electrónico" name="email"/>
                        </div>
                        <div className="col-8 form-group pt-2 mx-auto">
                            <input type="text" className="form-control" placeholder="Asunto" name="subject"/>
                        </div>
                        <div className="col-8 form-group pt-2 mx-auto">
                            <textarea className="form-control" id="" cols="30" rows="8" placeholder="Mensaje" name="message"></textarea>
                        </div>
                        <div className="col-8 pt-3 mx-auto">
                            <input type="submit" className="btn" value="ENVIAR"></input>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}
 