
import '../../App.css';
import './Mision.css';
import React from 'react';
import Titleimage from '../Titleimage';
//import {motion} from 'framer-motion';


function Mision() {
 
  
  return (
    
    <div>
    
<Titleimage heading='Misión' />
<div className='mision'>
<div className='left'>
  <div className='img-container'>
  <img src='/images/img16.jpg' alt=''className='mision-me' loading='auto'/>  
  </div>
  </div>
<div className='right'>

  <h2>Nuestra Misión</h2>
     <p>
     Apoyar a las mujeres de origen hispano y sus familias mediante el desarrollo de una variedad
    de eventos y actividades que satisfagan sus necesidades sociales, culturales, educativas y laborales.
     </p>
     
    
</div>
    
  
  </div>
  
  </div>

  
     

  
  );
}

export default Mision;
