
import '../../App.css';
import './Historia.css';
import React from 'react';
import Titleimage from '../Titleimage';
//import {motion} from 'framer-motion';
//import {animationOne, transition} from '../../animations';


function Historia() {
 
  
  return (
 
<div>
<Titleimage heading='Historia' />



    <div className='historia'>

<div className='left'>
<div className='img-container'>
<img src='/images/img16.jpg' alt='' className='historia-me'loading='auto'/>  

</div>
   
</div>
 
   
  <div className='right'>

     <h2>Nuestra Historia</h2>
     <p>
     Hwasa es una asociación de mujeres hispanas sin afiliación política y discriminación, establecida sin fines de lucro para representar y promover los intereses de las mujeres y sus familias en la ciudad de Adelaide y el Sur de Australia.
Constituida en Julio de 2019 por un grupo de diez mujeres de diversos países hispanos cuyo objetivo principal es atender las necesidades de las mujeres y sus familias, buscando enriquecer sus vidas, para desarrollar al máximo su potencial personal, social y profesional. 

     </p>
    
   
    
   
     

     </div>
     </div>
    
     

  
     </div>
     

   
 
 
     

  
  );
}

export default Historia;
