import React from 'react';
import { Link } from 'react-router-dom';
import './Cards4.css';





function Cards4() {
  return (
    <div className='cards4'>
      <div className='cards4-font'> 
      <h1>AGENCIAS DE APOYO</h1>
      <p>HWASA aprecia el apoyo de las siguientes instituciones</p>
 
      </div>

<Link
class='mccsa-logo-link'
to={{ pathname: "https://mccsa.org.au/" }}
              target='_blank'
              rel='noopener' // good security practice
              aria-label='Mccsa'
              >

<img src='/images/MCCSA-Logo.png' alt='Logo'  className='mccsa-logo-link' loading='auto' width="597" height="120"/>

  
</Link>        


</div>

    
   

     







  
  );
}

export default Cards4;
