
import '../../App.css';
import './Vision.css';
import React from 'react';
import Titleimage from '../Titleimage';
//import {motion} from 'framer-motion';

function Vision() {
 
  
  return (
    <div> 
      <Titleimage heading='Visión' />
    <div className='vision'>
<div className='left'>
<div className='img-container'>
<img src='/images/img16.jpg' alt=''className='vision-me' loading='auto'/>    
    
   </div>

</div>
  
     
     <div className='right'

     
     >

     <h2>Nuestra Visión</h2>
     <p>
     Todas las mujeres de origen hispano y sus familias en Australia del Sur aumentan su nivel de participación en la sociedad.
     </p>
    
    
    
     </div>
     

  
  </div>
     

  </div>



   
 
 
     

  
  );
}

export default Vision;
