import 'bootstrap/dist/css/bootstrap.css'; 
import { Nav, Navbar, NavDropdown } from 'react-bootstrap'
import './Menu.css';
import {Button, ButtonLabel} from './ButtonElement';
import React, {useState} from 'react';
import {Link} from 'react-router-dom';




function Menu() {
  
  /*const handleClick = () => {
    console.log("hello, ninjas");
  } */

  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  }



  return(
    <>
 
    <div className='container d-inline'>
      <Navbar className="list" variant="light" 
      sticky='top'
      expand="md" // mobile version size
     collapseOnSelect
     
      >
        <Navbar.Brand>
       
<Link
to='/'> 
<img alt="logo" src="/images/logo1.png" title="Hispanic Women's Association of SA" width="auto" max-height="400%" height="110px" vloading="auto"/>{""}

</Link>

        </Navbar.Brand>
        

         <Navbar.Toggle/>

       
          <Navbar.Collapse className='color-nav'>
   
          <Nav className='nav justify'>
           <Link to='/'className='nav-link'>Inicio</Link>
          <NavDropdown title="Sobre Nosotras" id="basic-nav-dropdown">
          <Link to='sobre-nosotras-historia'> <NavDropdown.Item href="#sobre nosotras/historia">Historia</NavDropdown.Item></Link>
          <Link to='sobre-nosotras-mision'><NavDropdown.Item href="#sobre nosotras/mision">Misión</NavDropdown.Item></Link>
          <Link to='sobre-nosotras-vision'> <NavDropdown.Item href="#sobre nosotras/vision">Visión</NavDropdown.Item></Link>
          <Link to='sobre-nosotras-nuestro-equipo'><NavDropdown.Item href="#sobre nosotras/nuestro-equipo">Nuestro Equipo</NavDropdown.Item></Link>
          </NavDropdown>
          <Link to='/eventos'> <Nav.Link href="#eventos">Eventos</Nav.Link></Link>
          <NavDropdown title="Servicios" id="basic-nav-dropdown">
         <Link to='servicios'> <NavDropdown.Item href="#servicios/servicio1">Servicio 1</NavDropdown.Item></Link>
         <Link to='servicios'> <NavDropdown.Item href="#servicios/servicio2">Servicio 2</NavDropdown.Item></Link>
         <Link to='servicios'> <NavDropdown.Item href="#servicios/servicio3">Servicio 3</NavDropdown.Item></Link>
          </NavDropdown>
          <NavDropdown title="Programas">
         <Link to='programas-la-familia'> <NavDropdown.Item href="#Programas/la familia">La Familia Hispana</NavDropdown.Item></Link>
         <Link to='programas-disfruta-en-línea'> <NavDropdown.Item href="#Programas/disfruta en línea">Disfruta en Línea</NavDropdown.Item></Link>
         <Link to='programas-orientación-información'> <NavDropdown.Item href="#Programas/orientación e información">Orientación e Información</NavDropdown.Item></Link>
         <Link to='programas-oportunidades-laborales'> <NavDropdown.Item href="#Programas/oportunidades laborales">Oportunidades Laborales en SA</NavDropdown.Item></Link>                
        </NavDropdown>
         <Link to='/boletin'> <Nav.Link href="#boletin">Boletin Informativo</Nav.Link></Link>
          <NavDropdown title="Únete a HWASA">
          <Link to='unete-a-hwasa-socia'><NavDropdown.Item href="#Únete a HWASA/socia">Socia</NavDropdown.Item></Link>
          <Link to='unete-a-hwasa-voluntaria'> <NavDropdown.Item href="#Únete a HWASA/voluntaria">Voluntaria</NavDropdown.Item></Link>
                    
          </NavDropdown>
       
          
         <Button  
         className='btns'
       
         onMouseEnter={onHover}
         onMouseLeave={onHover}
           primary='true'
           dark='true'>
           <Link to='/contáctanos'> <ButtonLabel href="#contactanos">Contáctanos</ButtonLabel> </Link>
      
             
           </Button>
         
           <div id="google_translate_element" className='inline-flex'></div>  
      
       


  
  

        


        </Nav>

          </Navbar.Collapse>
          

         
    
      </Navbar>
     { /* <div className="content">
This is a content
  </div> */}
    </div>
    </>
   
  );
}

export default Menu; 
