import React from 'react';
import './Cards3.css';
import './Cards2.css';
import Slider2 from './Slider/Slider2';




function Cards2() {


  return (
    <div className='cards2'>
      <h1>Próximos Eventos </h1>
   <Slider2 />
  
      
        </div>

        

  );
}

export default Cards2;









