import React from 'react';
import '../../App.css';
import './Events.css';
import Titleimage from '../Titleimage';

function Services() {
  return (
    <div>
   <Titleimage heading='Servicios' />
   <div className='eventos'>
    <div className='eventos-container'>
    
    
    
  
  </div>
    </div>
    </div>


  )

}

export default Services;
