
import '../../App.css';
import './NuestroEquipo.css';
import React from 'react';
import Titleimage from '../Titleimage';
//import {motion} from 'framer-motion';


function NuestroEquipo() {
 
  
  return (
    
    <div>
      <Titleimage heading='Nuestro Equipo' />

    <div className='nuestro-equipo'>
 
    <div className='left'>
<div className='img-container'>

<img src='/images/img16.jpg' alt=''  className='equipo-me' loading='auto'/>    

</div>

</div>
     <div className='right'>

     <h6>Patricia Ríos</h6>
     <p>
     Patricia es muy conocida y respetada en su comunidad. Ella participó en la formación de muchas agencias latinoamericanas: Federation of Spanish Speaking Communities of South Australia, Casa Chile, Alepueche Dance Group, Gabriela Mistral Spanish Speaking School and Cultural Centre, Spanish Speaking Communities Network of South Australia and Hispanic Women’s Association of South Australia.
 </p>
<p> 
 Ella ha trabajado con muchas organizaciones incluyendo el Migrant Resource Centre, Moreton Bay Regional Council ( Queensland), Carers SA, BENE, inclusive Directions, Catalyst Foundation, Northern Carers Network, City of Playford and AMES en todas siempre buscando oportunidades para que los inmigrantes florezcan y excedan.
 </p>
 <p>
 También ella anima a las agencias de mainstream a inspirar y estimular sus políticas de acceso y equidad.
 </p>
 <p>
 Ella también administra su negocio con su pareja de carpintería.
 
 
     </p>
    
 
    
     </div>
     

     <div className='left'>
<div className='img-container'>

<img src='/images/Leticia.jpg' alt=''  className='equipo-me' loading='auto'/>    

</div>

</div>
     <div className='right'>
      
 

<h6>Leticia Cubero Domínguez</h6>
     <p>
     Leticia llegó desde Galicia, España, hace diez años. Es química y trabaja en el sector de la biotecnología, en la investigación de nanotecnología aplicada a la detección de cáncer. En su tiempo libre, se dedica a realizar actividades de voluntariado, practicar yoga y meditación y explorar Australia del Sur.

     </p>
     <p>
     Una de sus pasiones es poder ayudar y orientar a mujeres emigradas a Australia ofreciendo su apoyo y consejos sobre su propia experiencia en este país, y por esta razón se unió hace dos años a HWASA, donde se realiza una labor comunitaria muy importante. 
     </p>
     <p>
     Su entusiasmo por mantener una comunidad de habla hispana fuerte y unida en Adelaida la llevó a participar en el comité del club español de SA y en la radio española de 5EBI, así como a organizar encuentros para hablar español y enseñar español como segunda lengua.
     </p>
    

    
     </div>

     <div className='left'>
<div className='img-container'>

<img src='/images/Soledad.jpg' alt=''  className='equipo-me' loading='auto'/>    

</div>

</div>
     <div className='right'>

   <h6>Soledad Santibanez</h6>
     <p>
     Soledad es una trabajadora social oriunda de Chile. Ha trabajado por más de seis años con niños y adultos con discapacidades intelectuales, físicas y con problemas de salud mental. Actualmente Soledad es directora de Support and Therapy at Home, empresa que apoya a personas que tienen algún tipo de discapacidad y buscan apoyo con actividades del día a día.  
     </p>
     <p>
     Soledad tiene una pasión por la justicia social y el bienestar de las personas, valores con los que lidera su empresa. Ella también ha trabajado en el área de protección de niños en Australia de Sur, con niños y jóvenes que ha vivido experiencias traumáticas, abuso y negligencia.
     </p>
     <p>
     En su tiempo libre, Soledad también disfruta mucho del arte y se dedica a pintar con acuarela inspirada en la naturaleza y sus bondades.
  </p>
  <p>
  Soledad está muy agradecida por esta oportunidad de trabajar en conjunto con HWASA y las comunidades hispanohablantes, y espera poder contribuir al desarrollo del área de discapacidad.
  </p>
    
    
     </div>

     <div className='left'>
<div className='img-container'>

<img src='/images/Bertha.jpg' alt=''  className='equipo-me' loading='auto'/>    

</div>

</div>
     <div className='right'>
    
  
<h6>Bertha Garrido</h6>
     <p>
     Curabitur vulputate velit ipsum, non tincidunt sem fermentum in. Nam porta interdum elit, ut rhoncus enim ultrices sit amet. 
     Pellentesque placerat metus sem, in tristique metus gravida eget. Nulla efficitur neque quis blandit bibendum. 
     Morbi euismod leo eget metus viverra, nec hendrerit ipsum dapibus. Aenean ac tellus neque. Sed sit amet turpis pharetra, porta erat at, commodo quam. 
     Sed erat nunc, aliquam at enim eu, aliquam auctor erat. Morbi rhoncus quam in scelerisque lobortis. Duis congue lacus sem. 
     Proin tincidunt est condimentum cursus posuere. Aenean id lacus quis lacus efficitur tempus vel quis ipsum. Quisque sed dolor id lorem interdum tincidunt at nec mi. 
     Ut molestie magna sit amet ex efficitur suscipit. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. 
     </p>
   
       
     </div>

     <div className='left'>
<div className='img-container'>

<img src='/images/Jaqueline.jpeg' alt=''  className='equipo-me' loading='auto'/>    

</div>

</div>
     <div className='right'>
         
  <h6>Jaqueline Rivieri</h6>
     <p>
     Curabitur vulputate velit ipsum, non tincidunt sem fermentum in. Nam porta interdum elit, ut rhoncus enim ultrices sit amet. 
     Pellentesque placerat metus sem, in tristique metus gravida eget. Nulla efficitur neque quis blandit bibendum. 
     Morbi euismod leo eget metus viverra, nec hendrerit ipsum dapibus. Aenean ac tellus neque. Sed sit amet turpis pharetra, porta erat at, commodo quam. 
     Sed erat nunc, aliquam at enim eu, aliquam auctor erat. Morbi rhoncus quam in scelerisque lobortis. Duis congue lacus sem. 
     Proin tincidunt est condimentum cursus posuere. Aenean id lacus quis lacus efficitur tempus vel quis ipsum. Quisque sed dolor id lorem interdum tincidunt at nec mi. 
     Ut molestie magna sit amet ex efficitur suscipit. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. 
     </p>
    
     
     </div>

     <div className='left'>
<div className='img-container'>

<img src='/images/img16.jpg' alt=''  className='equipo-me' loading='auto'/>    

</div>

</div>
     <div className='right'>
 
<h6>Nilda Centurion Simmons</h6>
     <p>
     Nilda es miembro fundadora de la Comunidad Paraguay (2001/04) y fue Coordinadora del Grupo de Danza Paraguay (2002/06)
     </p>
     <p>
     Durante los últimos 5 años ha sido miembro del Grupo de Arte de Mayores de 50 de Buena Amistad como tesorera y durante los últimos 8 ha sido miembro del Grupo de Inspiraciones de Habla Hispana como voluntaria de la Ciudad de Playford.
     </p>
     <p>
     Formó parte de la Red de Comunidades de Habla Hispana de SA, un grupo paraguas desde 2010 que tiene como objetivo coordinar eventos en las comunidades de habla hispana.
</p>
<p>
Su último compromiso comunitario es tesorera de la Asociación de Mujeres Hispanas de SA.
</p>
<p>
Su marido trabaja por cuenta propia y ella lleva la administración del negocio.
</p>
  
    
     </div>


    
     </div>

     
 
    
     </div>

  
    
  
  

     

  



   
 
 
     

  
  );
}

export default NuestroEquipo;
